/* global G */

/**
 * Provide UI Hook.
 *
 * Uses native UI adapter provide method.
 *
 * It provides CONTEXT for the UI layer
 *
 * @param {Gaia.AppModule.Spec} obj - app module
 * @return {function(...[*]): *[]}
 */
const provideUI = obj => async (...args) => {
  console.log('providing ui')
  await obj[G.ADAPTER][G.UI].provide(obj)
  return args
}


export default provideUI
