/* global G */
import { pipe } from '@gaia/util'
import { hasNamespace } from '@gaia/trait/has'
import { usesNamespace } from '@gaia/trait/uses'
import { withObjectFreeze, withDescriptor } from 'trait/with'
import api, { initialize } from './api'

const descriptor = 'adapter:Persistence'

/**
 * Initializes the persistence storage.
 *
 * @param {Object} args additional arguments
 * @return {Object}     the passed {@param args}
 */
const withInitialization = (args) => {
  (async () => await initialize())()
  return args
}

/**
 * UI Adapter.
 *
 * Adapter used to persist data locally.
 *
 * @memberOf Gaia.Adapter
 * @typedef {Function} Gaia.Adapter.Persistence
 * @property {Gaia.Adapter.Persistence.API} G.API
 */

/**
 * @returns {Gaia.Adapter.Persistence}
 */
const persistenceAdapter = () => pipe(
  withDescriptor(descriptor),
  withInitialization,
  hasNamespace(G.API),
  usesNamespace(G.API, api()),
  withObjectFreeze,
)({})

export default persistenceAdapter
