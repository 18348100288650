/* eslint-disable no-param-reassign,no-prototype-builtins,no-restricted-syntax */
import { def, isArr, isObj } from 'lib/util/index'

/**
 * Checks whether {@param object} doesn't have any property of its own.
 *
 * @param {object} object  the object to check
 * @returns {boolean}   {@code true} if {@param object} doesn't have any property of its own
 */
export const empty = (object) => {
  for (const property in object) {
    if (object.hasOwnProperty(property)) {
      return false
    }
  }
  return true
}

/**
 * Get either the {@code key} or {@code value} at a given {@code index} from an object.
 *
 * @param {Boolean} [key=true]  Whether to return the key or the value of the object
 *
 * @returns {(function(*, number=): (*|null))|*}
 */
const getProperty = (key = true) => (object, index = 0) => {
  if (object !== null && isObj(object) && !empty(object)) {
    return key
      ? Object.keys(object)?.[index] || null
      : Object.values(object)?.[index] || null
  }

  return null
}

/**
 * Get the name of the property at a given {@code index} from an {@code object}.
 *
 * @type {(function(*, number=): (*|null))|*}
 */
export const getPropertyName = getProperty(true)

/**
 * Get the value of the property at a given {@code index} from an {@code object}.
 *
 * @type {(function(*, number=): (*|null))|*}
 */
export const getPropertyValue = getProperty(false)

/**
 * Returns a copy of {@param object} in which all property Symbols (recursively) are replaced with
 * normal properties, whose name is the description of the Symbol in uppercase form.
 *
 * OPTIMIZE: make it iterative and avoid circular references.
 *
 * @param {object} object the object to simplify
 * @return {{}|*}         a copy of {@param object} with all symbols converted to normal properties
 */
export const clone = (object) => {
  if (object !== null) {
    if (isObj(object)) {
      const result = {}
      Object.getOwnPropertyNames(object).reduce((obj, name) => {
        obj[name] = clone(object[name])
        return obj
      }, result)
      Object.getOwnPropertySymbols(object).reduce((obj, symbol) => {
        obj[symbol.description.toUpperCase()] = clone(object[symbol])
        return obj
      }, result)
      return result
    }
    if (isArr(object)) {
      return object.map(clone)
    }
  }
  return object
}

/**
 * Traverses {@param scope} looking for the properties of it that match with those from
 * {@param object}. Every match calls {@param callback} with the current {@param path} and the
 * {@param object}'s matching property's current value as parameters. If {@param callback} returns
 * something other than {@code undefined}, that value will be set as the found property's value.
 *
 * @param {object} object       the object to traverse
 * @param {object} scope        an object mirroring the properties of {@param object} to be
 *                              traversed, i.e. for which we want {@param callback} to be called
 * @param {function} callback   a function called everytime a property inside {@param scope} is
 *                              also found inside {@param object}
 * @param {string[]} [path=[]]  the current path
 * @private
 */
export const query = (object, scope, callback, path = []) => {
  Object.keys(scope)
    .filter(key => scope[key])
    .forEach((key) => {
      const currentPath = [...path, key]
      if (isObj(object[key]) && isObj(scope[key])) {
        query(object[key], scope[key], callback, currentPath)
      } else if (def(object[key])) {
        const result = callback(currentPath, object[key])
        object[key] = def(result) ? result : object[key]
      }
    })
}
