/* eslint-disable no-param-reassign */
/* global G */
import intlManager from '@gaia/manager/intl'
import ReactMarkdown from 'react-markdown'
import { isStr } from 'lib/util'

const _navigatorLanguage = () => navigator.language?.split('-')[0]

const _appDefaultLanguage = obj => obj[G.CONFIGURATION].defaults.language

const _initialLanguage = obj => _appDefaultLanguage(obj) || _navigatorLanguage()

/**
 * ReactMarkdown wrapper
 *
 * @param {String} t        the translation string
 * @param {Object} options  options for markdown
 *
 * @return {JSX.Element}
 * @private
 */
const _markdown = (t, options) => (
  <ReactMarkdown
    linkTarget={options.target || '_blank'}
    disallowedElements={['p']} // We don't want paragraph tags for simple string
    unwrapDisallowed={true} // but we want the strings themselves
    components={{
      img: ({ alt, src, title }) => (
        <img
          alt={alt}
          src={src}
          title={title}
          style={{
            ...title === 'icon' && {
              width: 20,
              height: 20,
              verticalAlign: 'middle',
              marginBottom: '0.25em',
            },
          }}
        />
      ),
    }}
  >
    {t}
  </ReactMarkdown>
)
/**
 * Intl Adapter
 *
 * Initializes the {@link intlManager}, sets its initial language and extends it with two methods.
 *
 * @memberOf Gaia.Adapter#
 * @typedef Gaia.Adapter.Intl
 * @property {function(_key: string, options: object)} _t
 * @property {function(language: string)} set
 * @property {function} defaultLanguage
 * @property {function} getLocaleDate
 * @property {function(_key: string, options: object): string} markdown
 *
 * @param {Gaia.Web.Application} obj - web application
 * @returns {function(): Promise<Gaia.Adapter.Intl>}
 */
const intlAdapter = obj => async () => {
  const defaultLanguage = _initialLanguage(obj)
  const manager = await intlManager(obj)()

  const _set = async (lng, ...args) => {
    obj[G.SESSION][G.STATE][G.LANGUAGE] = lng
    await manager.set(lng, ...args)
  }

  await _set(defaultLanguage)

  return Object.create(manager, {
    set: {
      value: _set,
      iterable: true,
      enumerable: true,
    },
    defaultLanguage: {
      value: async () => await _set(defaultLanguage),
      iterable: true,
      enumerable: true,
    },
    getLocaleDate: {
      value: (date, options) => new Date(date).toLocaleString(
        obj[G.SESSION][G.STATE]?.[G.LANGUAGE] || defaultLanguage || 'en',
        options || {
          dateStyle: 'medium',
        },
      ),
      iterable: true,
      enumerable: true,
    },
    markdown: {
      value: (key, options) => {
        const t = manager._t(key, options)
        const mdOptions = {
          ...options.target && { target: options.target },
        }

        /**
         * {@code options.md} may get set by the uiAdapter. If it's set, it means we should
         * wrap the string in a markdown component.
         */
        return isStr(t) && options.md ? _markdown(t, mdOptions) : t
      },
      iterable: true,
      enumerable: true,
    },
  })
}

export default intlAdapter
