/* eslint-disable arrow-body-style */
/* global G */
import { curry, debounce } from 'lib/util'

let timeoutId
let controller
let finish
let error = false

/**
 * Aborts the current request and hides the loader whenever the user clicks cancel on the timeout
 * dialog.
 *
 * @param {Gaia.Web.Application} obj        the web platform application
 * @param {AbortController} abortController current request's controller
 */
const handler = (obj, abortController) => () => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  abortController.abort()
  eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))
}

/**
 * Shows the timeout dialog with a button to cancel the current request.
 *
 * @param {Gaia.Web.Application} obj        the web platform application
 * @param {AbortController} abortController current request's controller
 */
const showTimeoutDialog = (obj, abortController) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  const intl = obj[G.ADAPTER][G.INTL]
  const cancelHandler = handler(obj, abortController)

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: intl._t(
      'dialog.error.timeout.title',
      {
        ns: 'common',
        _key: 'dialog.error.timeout.title',
        defaultValue: 'Sorry',
      },
    ),
    text: intl._t(
      'dialog.error.timeout.text',
      {
        ns: 'common',
        _key: 'dialog.error.timeout.text',
        defaultValue: 'Your request is taking too long. You can keep waiting or cancel the'
                      + ' operation.',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: intl._t(
          'button.keepWaiting',
          {
            ns: 'common',
            _key: 'button.keepWaiting',
            defaultValue: 'Wait',
          },
        ),
      },
      cancel: {
        key: 'cancel',
        value: intl._t(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'Cancel',
          },
        ),
      },
    },
    cancelHandler,
    elevated: true,
  })
}

/**
 * Returns {@code true} if the application's loader overlay should be displayed or {@code false}
 * otherwise.
 *
 * @param {object} args         the request's arguments
 * @param {boolean} args.loader whether to force the loader for the current request
 * @param {string} args.method  the request method
 * @param {string} args.url     the url of the request
 * @returns {boolean}   {@code true} if the loader should be displayed
 */
const shouldDisplayLoader = (args) => {
  return args.url.match(/drilldown/) || (args.method !== 'GET' && !args.url.match(/search|list|fetch|intl/))
}

/**
 * Loader middleware handler.
 *
 * Blocks screen interaction with a loader while some HTTP calls are being performed.
 *
 * @type {Gaia.Adapter.Http.Middleware}
 * @param {Gaia.Web.Application} obj  the web platform application
 * @param {function} next             the next middleware function
 * @param {object} args               the request's arguments
 * @return {object}                   the response object
 */
export default curry(async (obj, next, args) => {
  let result
  error = false

  if (shouldDisplayLoader(args)) {
    const eventBus = obj[G.ADAPTER][G.EVENTS]
    // recycle or create request's AbortController
    controller = !controller || controller.signal.aborted ? new AbortController() : controller
    const { signal } = controller
    // show loader
    eventBus.dispatch(eventBus.type(G.LOAD, G.INIT))
    try {
      // clear previously set timeout dialog's countdown
      clearTimeout(timeoutId)
      // start timeout dialog's countdown
      timeoutId = setTimeout(() => showTimeoutDialog(obj, controller), 10000)
      // call next middleware
      result = await next({ ...args, signal })
    } catch (e) {
      error = true
      throw e
    } finally {
      // clear previously set timeout dialog's countdown
      clearTimeout(timeoutId)
      // debouncing finish call, in case there are more requests soon after this one
      finish = finish || debounce(() => {
        // hide loader
        eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))
        // close timeout dialog
        if (!error) {
          eventBus.dispatch(eventBus.type(G.DATA, G.DONE))
        }
      }, 500)

      finish()
    }
  } else {
    result = await next(args)
  }
  return result
})
