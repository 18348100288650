import { useState, useRef, useLayoutEffect, useContext, Suspense } from 'react'
import ApplicationContext from '@platform/react/context/application'
import { debounce } from 'lib/util'
import PlatformEvent from 'lib/util/event'
import { useMemoRef, preload } from '@platform/react/hook'

/**
 * High Order Component for displaying count or single entry data
 *
 * @param {JSX.Element} template    the child element to return
 * @param {Number} [debounced]      optional debounce duration in ms
 * @param {Object} cell             subscription information
 * @param {Object} events           event handlers
 * @param {Object} props            props to pass to the child
 * @returns {JSX.Element}
 * @constructor
 */
const CellHOC = useMemoRef((props, ref) => {
  const { template, debounced, cell, events } = props

  const { socket: { ref: socket } } = useContext(ApplicationContext)
  const { options, ref: key, handler } = cell
  const refType = Object.keys(options).pop()
  const event = new PlatformEvent(new CustomEvent('cell', { detail: { key, type: refType } }))
  const socketKey = useRef(`${key}_${refType}`).current

  /**
   * Use the {@param props} passed from the parent as the
   * initial value. E.g.:
   *
   * @example
   * {
   *     ...
   *     options: {
   *         ...
   *         keys: {
   *             ...
   *             foo: true, // this
   *             bar: true  // stuff
   *         }
   *     }
   * }
   */
  const [cellData, setCellData] = useState(props)
  const [ChildComponent, setChildComponent] = useState(preload(template))

  useLayoutEffect(() => {
    setCellData(props)
  }, [props])

  useLayoutEffect(() => {
    setChildComponent(preload(template))
  }, [template])

  useLayoutEffect(() => {
    /**
     * The parent listItem component may unmount this one.
     * Make sure to only setState() when mounted.
     */
    let isMounted = true
    const listener = async () => {
      /**
       * The listener is expected to return an object
       * containing the data to be updated/added as well as a
       * callback instructing us how to do so!
       *
       * CAUTION: The HOC passes the state directly to its child
       * component, so if the child expects {@code { foo, bar }}
       * as props, make sure {@code addData} sets the state appropriately.
       *
       * If the returned {@code data} is null, the state is untouched.
       */
      const { data, addData } = await handler(event)
      // data may be {@code 0}, which is falsy
      data !== null && isMounted && setCellData(prevState => addData(prevState, data))
    }

    /**
     * There is {@link useDebounce}, but we can't use
     * it here, unfortunately.
     */
    const debouncedListener = debounced
      ? debounce(listener, debounced)
      : null

    socket.sub(socketKey, () => {
      socket.on(socketKey, debouncedListener ?? listener)
    })
    return () => {
      isMounted = false
      socket.unsub(socketKey, () => {
        socket.off(socketKey, debouncedListener ?? listener)
      })
    }
  }, [socketKey])

  return (
    <Suspense ref={ref} fallback={<></>}>
      <ChildComponent
        {...{ events, ...cellData }}
      />
    </Suspense>
  )
}, props => [props])

export default CellHOC
