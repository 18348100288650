import rest from '@platform/adapter/http/rest'
import middleware, { defaultMiddleware } from '@platform/adapter/http/middleware'
import { isFn } from 'lib/util'

const {
  post, put, get, patch, del,
} = rest

/**
 * An array or a callback function that gets passed the {@link middleware} middlewares and must
 * return an array. In both cases, the array is expected to contain the middlewares to be used for a
 * specific request.
 *
 * @typedef {array|function(Object):array} MiddlewareOptions
 */

/**
 * @type {{middleware: MiddlewareOptions}} middleware
 */
const defaultOptions = {
  middleware: defaultMiddleware,
}

/**
 * Intercepts {@param methods} by building a Middleware Pattern call chain with their provided
 * options. Otherwise, it returns {@param methods} without modifying them.
 *
 * The middleware options accepts a callback with {@link middleware} as its first parameter. The
 * callback must then pick some of them and return an array containing those to be used for that
 * specific call instead of the ones in {@link defaultOptions}.
 *
 * @param {Gaia.Web.Application} obj                    the Web platform Application
 * @param {object} methods                              the HTTP methods to intercept
 * @return {object}
 */
const init = (obj, methods) => Object.keys(methods).reduce((acc, method) => {
  /**
   * Performs a request with HTTP Method {@link method}.
   *
   * @param {Object} args                           request's parameters
   * @param {string} args.url                       partial request's url
   * @param {Object|array} args.params              request body's content
   * @param {Object} args.headers                   request headers
   * @param {AbortSignal} args.signal               request's abort signal
   * @param {Object} options                        additional request's options
   * @param {MiddlewareOptions} options.middleware  the middlewares to be used for the request
   * @return {Promise<any>}
   */
  acc[method] = async (args, options = defaultOptions) => {
    const chain = isFn(options.middleware) ? options.middleware(middleware) : options.middleware
    const callChain = chain.reduce((prev, fn) => fn(obj, prev), methods[method])
    return await callChain({ ...args, method: method.toUpperCase() })
  }
  return acc
}, {})

/**
 * Http Adapter API
 *
 * Exposes intercepted {@link post}, {@link put}, {@link get}, {@link patch} and {@link delete}
 * methods to perform HTTP calls.
 *
 * @memberOf Gaia.Adapter#
 * @typedef Adapter.API
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @returns {object}                  the Http Adapter API
 */
export default obj => init(obj, {
  get,
  post,
  put,
  patch,
  delete: del,
})
