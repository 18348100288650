/**
 * HttpError constructor.
 *
 * Extends the base Error class by adding a code property to it, allowing us to pass the actual HTTP
 * status codes through throw statements.
 *
 * @param {string} message  a short text informing about the error
 * @param {number} code     an identifier for the error
 */
function HttpError(message, code) {
  this.message = message
  this.code = code
}

HttpError.prototype = Object.create(Error.prototype)
HttpError.prototype.constructor = HttpError
HttpError.prototype.name = 'HttpError'

export default HttpError
