/* eslint-disable no-restricted-syntax */
/* global G */
import { PING_URL } from 'lib/util/environment'

const settings = {
  url: PING_URL,
  intervalMs: 30000,
  timeoutMs: 3000,
}

let worker

/**
 * Sets the status of the G.ONLINE session of the application to true if, according to the
 * {@link SharedWorker}, the application has access to the Internet or to false otherwise. It also
 * dispatches that same value through the application's event bus to all listeners of [G.HTTP,
 * G.ONLINE] type.
 *
 * Callback function used to handle messages sent by the {@link SharedWorker} executing the ping
 * script.
 *
 * @param {Gaia.Web.Application} obj  native web application
 */
const handleMessage = obj => async (event) => {
  const sessionState = obj[G.ADAPTER][G.SESSION][G.STATE]
  const eventBus = obj[G.EVENTS]
  const { online } = event.data

  sessionState[G.ONLINE] = online
  eventBus.dispatch(eventBus.type(G.HTTP, G.ONLINE), { online })
}

/**
 * Initializes a {@link SharedWorker} singleton with the ping script, registers
 * {@link handleMessage} as its {@code onmessage} listener and adds a new listener of type [G.HTTP,
 * G.UPDATE] to {@param obj}'s event bus to allow sending messages to it from anywhere.
 *
 * @param {Gaia.Web.Application} obj  native web application
 */
const init = (obj) => {
  const eventBus = obj[G.EVENTS]

  if (!worker) {
    worker = new SharedWorker('./workers/http/ping.js', { name: 'ping' })
    worker.port.onmessage = handleMessage(obj) // setting onmessage also initializes the WebWorker
    eventBus.add(eventBus.type(G.HTTP, G.UPDATE), () => worker.port.postMessage({}))
    worker.port.postMessage({ settings })
  }
}

export default init
